<template>
  <div ref="badge">
    <!-- Professional verification provided by Psychology Today -->
    <a href="https://www.psychologytoday.com/profile/1316958" class="sx-verified-seal"></a>
    <!-- End Verification -->
  </div>
</template>

<script>
export default {
  name: 'VerificationBadge',
  mounted() {
    // Create a new script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://member.psychologytoday.com/verified-seal.js';
    script.setAttribute('data-badge', '13');
    script.setAttribute('data-id', '1316958');
    script.setAttribute('data-code', 'aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s=');
    // Append the script element to the component's root element
    this.$refs.badge.appendChild(script);
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
