import info from '../../../info'

export default{
    home: {
        company: "ASA COUNSELING AND PSYCHOTHERAPY, LLC",
        welcome: "welcome!",
        name: "Chen Peng",
        title: "LPC, LMHC, MA",
        description: "<br>I am a licensed psychotherapist with multicultural background providing bilingual counseling services in English and Chinese for adults, adolescents, children, couples, and families. I have extensive experience in working with anxiety, depression, couple counseling, trauma, self-esteem, cultural adjustment, etc. <br><br>Therapy to me is a collaborative journey with clients towards a more thriving and satisfactory life state. I take a person-centered, humanistic approach integrated with other evidence-based strategies such as Acceptance and commitment therapy (ACT), Mindfulness, Emotion-focused therapy (EFT), and Gottman couple's therapy with a balance between Cognitive behavioral therapy (CBT) and Psychodynamics. <br><hr><i><small>When you stumble upon these words I've written, take a moment to appreciate your own willingness to strive for self-care and search for resources. We all face dark times, yet it's truly admirable to acknowledge our fragility and reach out for support. And here you are, on the brink of taking that pivotal first step.</small></i><br>",
    },
    
    insurance: {
        nav_title: "insurance",
        title: "health insurance networks.",
        description: "Our business currently accept the following insurance providers. We can also provide a superbill for out-of-network clients and help with reimbursement ."
    },

    free_consultation: {
        title: "book a free consultation.",
        description: "I offer a <b> 15-minute free consultation</b> to give you the time to explain your issue and to discuss how I can help. The consultation allows us to briefly know about each other and decide whether to proceed with the therapy. "
    },

    service: {
        nav_title: "service",
        title: "conditions I can help with.",
        description: "I provide services that span from therapy for depression and anxiety to trauma treatment, couples counseling and beyond.",
        description2: "In a comfortable and supportive atmosphere, I offer a highly personalized approach tailored to each of my clients individual needs to help attain the personal growth they’re striving for.",
        hide: "show less",
        show: "show more",
        items: [
            {
                name: "Anxiety",
                icon: info.icons.anxiety,
                description: "Anxiety is a normal and often healthy emotion. However, when a person regularly feels disproportionate levels of anxiety, it might become a medical disorder and lead to excessive nervousness, fear, apprehension, and worry."
            },
            {
                name: "Depression",
                icon: info.icons.depression,
                description: "Depression is a mood disorder that causes a persistent feeling of sadness, low motivation, loss of interest, sleeing issues, etc. It can affect how you feel, think and behave and can lead to a variety of emotional and physical problems."
            },
            {
                name: "Trauma",
                icon: info.icons.trauma,
                description: "Trauma is a response to a deeply distressing or disturbing event that overwhelms an individual’s ability to cope, causes feelings of helplessness, and diminishes their sense of self and their ability to feel the full range of emotions and experiences. Trauma can result from a one-time event or ongoing events."
            },
            {
                name: "Cross-Cultural Difficulties",
                icon: info.icons.acculturation,
                description: "Cross-cultural difficulties can include language barriers, cultural differences, homesickness, confusion about one's own cultural identifies, and more. Cross-cultural difficulties can affect a person’s mental health and well-being."
            },
            {
                name: "Self Esteem",
                icon: info.icons.selfesteem,
                description: "Low self-esteem, characterized by a persistent lack of confidence and negative self-perception, can stem from factors such as negative childhood experiences, unrealistic expectations, and societal pressures, leading to detrimental effects on mental health, relationships, and overall life satisfaction."
            },
            {
                name: "Relationships",
                icon: info.icons.relationship,
                description: "Interpersonal relationship difficulties often arise from issues such as poor communication, lack of trust, unresolved conflicts, differing values or expectations, and emotional or psychological barriers. These challenges can strain personal and professional relationships, leading to feelings of loneliness, frustration, and stress. "
            }
        ]
    },

    about: {
        nav_title: "about",
        title: "about me.",
        description: "After four years of experience at an outpatient clinic in lower Manhattan, I worked as assistant clinical director at a private practice in New Jersey for two years. Currently, I am the founder and owner of Asa Counseling and Psychotherapy in NJ.",
        education:{
            title: "education",
            data: [
                {
                  name: "New York University",
                  name2: "Counseling for Mental Health and Wellness",
                  detail: "Master of Arts",
                  description: ""
                },
                {
                  name: "University of Toronto",
                  name2: "Double Major in Psychology and Economics",
                  detail: "Bachelors of Science",
                  description: ""
                }
            ],
        },
        qualification:{
            title: "qualification",
            data: [
                {
                    name: "Licensed Professional Counselor",
                    name2: "License Number: 37PC00858900",
                    detail: "License State: New Jersey, USA",
                    description: ""
                },
                {
                    name: "Licensed Mental Health Counselor",
                    name2: "License Number: 012342",
                    detail: "License State: New York, USA",
                    description: ""
                }
            ],
        },    
    },

    contact:{
        nav_title: "contact",
        title: "contact us.",
        description: "Please email <a href='mailto:contact@asa-counseling.com'>contact@asa-counseling.com</a> or leave your message below.",
        name: "name",
        email: "email",
        message: "message",
        send: "Send"
    },
 
}