import Vue from 'vue'
import App from './App.vue'

import AOS from 'aos'
import 'aos/dist/aos.css'
import VueParallaxJs from 'vue-parallax-js'
import VueScrollTo from 'vue-scrollto'
import VueRouter from 'vue-router'
import VTooltip from 'v-tooltip'
import VueI18n from 'vue-i18n'  

import enLocale from './assets/locales/en.js'
import zhLocale from './assets/locales/zh.js'

var VueCookie = require('vue-cookie');

Vue.use(VTooltip)
Vue.use(VueRouter)
Vue.use(VueScrollTo)
Vue.use(VueCookie)
Vue.use(VueParallaxJs)
Vue.use(VueI18n)

Vue.config.productionTip = false

const routes = [
  { path: '/'}
]

const router = new VueRouter({
  mode:'history',
  routes // short for `routes: routes`
})

const messages = {
  en: enLocale,
  zh: zhLocale
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages // set locale messages
})

export default i18n

new Vue({
  created () {
    AOS.init()
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
