
import info from '../../../info'

export default {
    home: {
        company: "ASA COUNSELING AND PSYCHOTHERAPY, LLC",
        welcome: "欢迎！",
        name: "彭晨",
        title: "LPC, LMHC, MA",
        description: "<br>我是一名在美国纽约州和新泽西州执业的执照心理咨询师，并同时持有纽约州成瘾治疗高级咨询师执照。我拥有丰富的个体、儿童、家庭、及婚姻伴侣情感治疗经验，职业生涯总咨询时长5000+小时。我擅长的咨询领域包括亲密关系，复杂性创伤，情绪管理，自我探索，人际关系，跨文化适应等。<br><br>我致力于为来访者提供一个安全的、无偏见的、开阔的心理环境，从而与来访者共同进行一场探索自我和世界的旅程。我的咨询理念以人本主义流派为核心，整合了情绪导向、Gottman伴侣治疗、正念、接纳与承诺、认知行为、等其他基于临床证据的技术疗法。<br><hr><i><small>当你找到我，看到我写下的这句话时，请先感恩自己愿意为了自我关怀而作出努力、搜寻资源。每个人都会经历黑暗的时刻，但能够有勇气承认自己的脆弱和无助、并积极寻求帮助是非常了不起的事。而此刻的你，即将迈出第一步。</small></i><br>",
    },

    insurance: {
        nav_title: "保险",
        title: "保险",
        description: "我们目前接受以下保险供应商。我们也可以为保险网络外（Out-of-network）来访提供咨询账单（Superbill），让您向保险公司申请网络外福利报销。"
    },
    
    free_consultation: {
        title: "预咨询",
        description: "我提供<b>15分钟的免费预咨询</b>，旨在让我们能初步地对彼此有更好的了解，再决定后续是否正式展开合作。",
    },

    service: {
        nav_title: "服务",
        title: "心理咨询服务",
        description: "我提供的咨询服务范围从抑郁和焦虑的治疗到创伤治疗、夫妻咨询等。",
        description2: "在一个安全和支持性的氛围中，我提供高度个性化的治疗方案，以满足每位来访的个体需求，帮助每个人实现自我成长。",
        hide: "收起",
        show: "展开",
        items: [
            {
                name: "焦虑",
                icon: info.icons.anxiety,
                description: "焦虑是一种正常且通常健康的情绪。然而，当一个人经常性地感到过度的焦虑时，它可能会成为一种精神疾病，并导致过度的紧张、恐惧、忧虑和担心。"
            },
            {
                name: "抑郁",
                icon: info.icons.depression,
                description: "抑郁是一种情绪障碍，会导致持续的悲伤感、低动力、兴趣丧失、睡眠问题等。它会影响你的感受、思维和行为，可能会导致各种情绪和身体问题。"
            },
            {
                name: "创伤",
                icon: info.icons.trauma,
                description: "创伤是对令人痛苦或困扰的事件的反应，它超出了个体应对的能力，导致无助感，削弱了他们的自我感和感受全面情感和经历的能力。创伤可能源自一次性事件或持续事件。"
            },
            {
                name: "跨文化困难",
                icon: info.icons.acculturation,
                description: "跨文化困难可能包括语言障碍、文化差异、思乡、多文化认同困难等。跨文化困难可能影响一个人的心理健康和幸福感。"
            },
            {
                name: "自尊",
                icon: info.icons.selfesteem,
                description: "低自尊，表现为持续缺乏自信和消极的自我认知，可能源自负面的童年经历、不切实际的期望和社会压力，导致对心理健康、人际关系。对整体生活满意度产生不利影响。"
            },
            {
                name: "人际关系",
                icon: info.icons.relationship,
                description: "人际关系困难通常源于沟通不畅、缺乏信任、未解决的冲突、价值观或期望的差异、情感或心理障碍等问题。这些挑战可能会对个人和亲密关系产生压力，导致孤独、挫折和压力。"
            }
        ]
    },

    about: {
        nav_title: "关于我",
        title: "关于我",
        description: "我曾于纽约市的一家心理治疗门诊部和成瘾治疗中心执业超过四年、于新泽西Jersey City的一家私人诊所担任副主任及咨询师督导长达两年。我目前在新泽西经营私人诊所Asa Counseling and Psychotherapy。",
        education: {
            title: "教育经历",
            data: [
                {
                    name: "纽约大学",
                    name2: "心理健康咨询专业",
                    detail: "硕士",
                    description: ""
                },
                {
                    name: "多伦多大学",
                    name2: "主修心理学与经济学",
                    detail: "学士",
                    description: ""
                }
            ]
        },
        qualification: {
            title: "资格认证",
            data: [
                {
                    name: "Licensed Professional Counselor",
                    name2: "执照号码: 37PC00858900",
                    detail: "新泽西州, 美国",
                    description: ""
                },
                {
                    name: "Licensed Mental Health Counselor",
                    name2: "执照号码: 012342",
                    detail: "纽约州, 美国",
                    description: ""
                }
            ]
        }
    },

    contact: {
        nav_title: "联系",
        title: "联系我",
        description: "请发送电子邮件至<a href='mailto:contact@asa-counseling.com'>contact@asa-counseling.com</a>或在下方留言。",
        name: "姓名",
        email: "电子邮件",
        message: "信息",
        send: "发送"
    },

}