<template>
  <div :class="{ 
    'bg-light': !nightMode, 
    'bg-dark2': nightMode,
    'text-light': nightMode }" 
    class="p-st">
    <div class="container py-4">
      <div
        class="text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <span
          class="title text-center"
          :class="{ pgray: !nightMode, 'text-light': nightMode }"
          >{{ $t('insurance.title') }}</span
        >
      </div>
      <hr
        width="50%"
        :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"
      />
      <div class="col-lg-8 col-md-10 mx-auto text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000">
        <p v-html="$t('insurance.description')"></p>
      </div>
      <br />
      <div class="row">
        <div
          class="col-xl-4 col-bg-4 col-md-4 col-sm-12 text-center pb-2 px-4"
          v-for="(insurance, idx) in insurances"
          :key="insurance.title"
          data-aos="fade-up"
          data-aos-offset="10"
          data-aos-delay="30"
          :style="{ 'transition-delay': idx / 4.2 + 's' }"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <div class="bg-div" v-if="insurance.picture_light">
            <img v-if="nightMode" class="icon-dark" :src="insurance.picture_light" />
            <img v-else class="icon-dark" :src="insurance.picture_dark" />
          </div>
          <div class="bg-div" v-else>
            <div class="title2 pt-2"><br>{{ insurance.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import info from "../../info";

export default {
  name: "Insurances",
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      // insurance_description: info.insurance_description,
      insurances: info.insurances,
    };
  },
};
</script>

<style scoped>

.icon-dark img {
  filter: invert(100%);
}

img {
  width: 10rem;
  height: 7rem;
}

.title {
  font-size: 30px;
  font-weight: 500;
}
.title1 {
  font-size: 24px;
  font-weight: 400;
}

.title2 {
  font-size: 20px;
  font-weight: 500;
}

.title3 {
  font-size: 16px;
  font-weight: 400;
}

.fa {
  color: #ecc442;
  font-size: 40px;
  transition: all 0.5s;
}

.fas {
  color: #ecc442;
  font-size: 40px;
  /* font-weight: bold; */
  transition: all 0.5s;
}
</style>
