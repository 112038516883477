<template>
  <div class="logo-div">
    <img :src="logo_picture" alt="logo_name" />
  </div>
</template>

<script>
import info from "../../../info";

export default {
  name: "Logo",
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      logo_picture: info.logo_picture,
      logo_name: info.logo_name,
      name: info.name,
      company: info.company,
      dot: ".",
    };
  },
};
</script>

<style scoped>
.logo-div:hover .logo-title-name {
  letter-spacing: 0px;
  transition: 0.5s all;
}

img {
  width: 180px;
  height: auto;
}

.logo-title-name {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 2px;
  transition: 0.5s all;
}

.logo-title-dot {
  font-size: 30px;
  font-weight: 700;
}

.logo-title-name-2 {
  font-size: 20px;
  font-weight: 500;
}

.text-wrap {
  white-space: pre-wrap;
}

.inline {
  display: inline;
}
</style>
