let config = {
  emailjs: {
    serviceID: "service_a4bsrhh",
    templateID: "template_ecfakbf",
    userID: "VE1DbYFt1LVPgUtXH"  // your public key
  },
  recaptcha: {
    sitekey: "6LcBEcopAAAAAICZRXg64e5BVBsX4ZUpd6vkGkW5"  // your site key
  },
};

export default config;
