<template>
  <div
    class="py-4 p-st"
    :class="{
      'bg-light': !nightMode,
      'bg-dark2': nightMode,
      'text-light': nightMode,
    }"
  >
    <div class="container py-4">
      <div
        class="text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <span
          class="title text-center"
          :class="{ pgray: !nightMode, 'text-light': nightMode }"
        >
          {{ $t('service.title') }}
        </span>
      </div>
      <hr
        width="50%"
        :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"
      />
      <div class="col-lg-8 col-md-10 mx-auto text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000">
        <p v-html="$t('service.description')"></p>
      </div>
      <br />
      <div class="row">
        <div
          class="col-xl-4 col-bg-4 col-md-4 col-sm-12 text-center pb-5 px-4"
          v-for="(item, idx) in $t('service.items')"
          :key="item.name"
          data-aos="fade-up"
          data-aos-offset="10"
          data-aos-delay="30"
          :style="{ 'transition-delay': idx / 4.2 + 's' }"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <i class="bg-div">
            <img v-if="nightMode" :src="item.icon.light" width="50rem" height="50rem"/>
            <img v-else :src="item.icon.dark" width="50rem" height="50rem"/>
          </i>
          <div class="bg-div">
            <div class="title2 pt-2">{{ item.name }}</div>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <hr width="40%" :class="{ pgray: !nightMode, 'bg-secondary': nightMode }" />
            <button
              class="btn btn-outline-secondary mx-2"
              @click="toggleDescription(idx)"
              :v-tooltip.bottom="isDescriptionVisible(idx) ? $t('service.hide') : $t('service.show')"
            >
              <i v-if="isDescriptionVisible(idx)" class="fas fa-minus"></i>
              <i v-else class="fas fa-plus"></i>
            </button>
            <hr width="40%" :class="{ pgray: !nightMode, 'bg-secondary': nightMode }" />
          </div>
          <div v-if="isDescriptionVisible(idx)" style="margin-top: 10px;">
            <span >{{ item.description }}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-10 mx-auto text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000">
        <p v-html="$t('service.description2')"></p>
      </div>
    </div>
  </div>
</template>



<script>
import Card from './helpers/Card.vue';

export default {
  name: "Specialties",
  components: {
    Card,
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      specialty_items: [],
      visibleDescriptions: [],
    };
  },
  methods: {
    toggleDescription(index) {
      if (this.visibleDescriptions.includes(index)) {
        this.visibleDescriptions = this.visibleDescriptions.filter(i => i !== index);
      } else {
        this.visibleDescriptions.push(index);
      }
    },
    isDescriptionVisible(index) {
      return this.visibleDescriptions.includes(index);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}
.title1 {
  font-size: 24px;
  font-weight: 400;
}

.title2 {
  font-size: 20px;
  font-weight: 500;
}

.title3 {
  font-size: 16px;
  font-weight: 400;
}

.fa {
  font-size: 15px;
}

.btn {
  border-color: #408dc9;
  color: #408dc9;
}

.btn:hover {
  background-color: #408dc9;
  border-color: #408dc9;
  color: white;
}

.btn:focus {
  background-color: #408dc9;
  border-color: #408dc9;  
  color: white;
}

.btn:focus {
  outline: none !important;
}

p {
  /* text-align: justify; */
  font-weight: 400;
}

</style>
