<template>
  <div
    class="py-4 p-st"
    :class="{
      'bg-white': !nightMode,
      'bg-dark': nightMode,
      'text-light': nightMode,
    }"
  >
    <div class="container">
      <div
        class="text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <span
          class="title text-center"
          :class="{ pgray: !nightMode, 'text-light': nightMode }"
          >{{ $t('free_consultation.title') }}</span
        >
      </div>
      <hr
        width="50%"
        :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"
      />
      <div class="text-center">
        <div
          class="col-lg-8 col-md-10 mx-auto"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1000"
        >
          <p v-html="$t('free_consultation.description')"></p>
        </div>
        <button
          class="mt-1 btn mb-3"
          data-aos="fade"
          data-aos-once="true"
          data-aos-duration="1000"
          data-aos-offset="50"
          onclick="document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });"
        >
        get started
      </button>
      </div>

    </div>
  </div>
</template>

<script>
import info from "../../info";

export default {
  name: "FreeConsultation",
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}
.title1 {
  font-size: 24px;
  font-weight: 400;
}

.title2 {
  font-size: 20px;
  font-weight: 400;
}

.title3 {
  font-size: 16px;
  font-weight: 400;
}

.pinput {
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 10px;
  width: 50%;
  transition: all 1s;
}

.btn {
  border-color: #408dc9;
  color: #408dc9;
  width: 50%;
}

.btn:hover {
  background-color: #408dc9;
  border-color: #408dc9;
  color: white;
}

.btn:focus {
  background-color: #408dc9;
  border-color: #408dc9;
  color: white;
}

.pgray-dark {
  background-color: #3c4148 !important;
}

@media screen and (max-width: 1000px) {
  .pinput {
    width: 90%;
  }
  .pinput {
    width: 90%;
  }

  .btn {
    width: 90%;
  }
}
</style>
