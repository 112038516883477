<template>
  <div
    :class="{ 
      'bg-white': !nightMode, 
      'bg-dark': nightMode,
      'text-light': nightMode }"
    class="pt-5 p-st"
  >
    <div
      class="container"
      data-aos="fade"
      data-aos-once="true"
      data-aos-duration="1000"
    >
      <div class="row align-items-top">
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 text-center">
          <div class="d-flex justify-content-center pt-3">
            <img :src="picture" />
          </div>
          <div class="pt-2">
            <span
              class="title text-bottom" style="font-size: 160%; font-weight: 500;"
              :class="{ pgray: !nightMode, 'text-light': nightMode }"
              >{{ $t('home.name') }}</span>
            <span
              class="title text-bottom" style="font-size: 90%; font-style: italic;"
              :class="{ pgray: !nightMode, 'text-light': nightMode }"
              >&nbsp;&nbsp;&nbsp;{{ $t('home.title') }}</span>
          </div>
          <div class="d-flex justify-content-center pt-2">
            <!-- Professional verification provided by Psychology Today -->
            <Badge />
            <!-- End Verification -->
          </div>

        </div>
        
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 pt-2">
          <span
            class="home-title"
            :class="{ pgray: !nightMode, 'text-light': nightMode }"
            >{{ $t('home.welcome') }}</span
          >
          <div>
            <p style="text-align: left;" v-html="$t('home.description')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import info from "../../info";

import Badge from "./helpers/Badge.vue";

export default {
  name: "Home",
  components: {
    Badge,
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      picture: info.flat_picture,

      insurance_description: info.insurance_description,

      linkedin: info.links.linkedin,
      psychology_today: info.links.psychology_today,
    };
  },
  methods: {
    open(link) {
      switch (link) {
        case "linkedin":
          window.open(this.linkedin, "_blank");
          break;
        case "psychology_today":
          window.open(this.psychology_today, "_blank");
          break;
      }
    },
  },
};
</script>

<style scoped>
.home-title {
  font-size: 28px;
  font-weight: 500;
}

img {
  max-width: 300px;
}

@media only screen and (max-width: 300px) {
  img {
    object-fit: cover;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid rgb(205, 205, 205);
  }
}

.fa {
  font-size: 15px;
}

.btn {
  border-color: #408dc9;
  color: #408dc9;
}

.btn:hover {
  background-color: #408dc9;
  border-color: #408dc9;
  color: white;
}

.btn:focus {
  background-color: #408dc9;
  border-color: #408dc9;  
  color: white;
}

.btn:focus {
  outline: none !important;
}

p {
  text-align: justify;
  font-weight: 400;
}

/* LEAVES */
</style>
